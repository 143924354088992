






































































































































































































































































import moment from "moment";
import List from "@/components/List";
import { StockLogResource, http } from "@/resources";
import { StockLog, StockLogItem } from "@/resources/interfaces";
import Component from "vue-class-component";
import StockLogDetail from "./StockLogDetail.vue";

@Component({
  components: { StockLogDetail }
})
export default class StockLogList extends List<StockLog> {
  name = "stock-log";
  resource = StockLogResource;
  searchQuery: Record<string, any> = {
    store: "",
    dept: "",
    reason: "",
    usage: "",
    status: [],
    stockOnly: false,
    mineOnly: false
  };
  saveSearchQueryKeys = [
    "store",
    "dept",
    "reason",
    "usage",
    "status",
    "mineOnly"
  ];
  totalAmount: number = NaN;
  totalQuantity: number = NaN;
  downloading = false;

  get isSearching() {
    return this.searchQuery.product || this.searchQuery.productName;
  }

  async queryData() {
    const queriedData = await (List as any).options.methods.queryData.call(
      this
    );
    if (!queriedData) return;
    this.totalAmount = queriedData.$headers["total-amount"] as number;
    this.totalQuantity = queriedData.$headers["total-quantity"] as number;
    return queriedData;
  }
  async itemUpdated() {
    this.queryData();
  }
  getProviderNames(stocklog: StockLog) {
    return Array.from(
      new Set(stocklog.items.map(i => i.providerName).filter(n => n))
    );
  }
  searchMatch(item: StockLogItem, matchIfNotSearching = false) {
    if (!item.quantity) return false;
    if (
      matchIfNotSearching &&
      !this.searchQuery.productName &&
      !this.searchQuery.product
    ) {
      return true;
    }
    if (this.searchQuery.productName) {
      return (
        item.product?.name.includes(this.searchQuery.productName) ||
        item.product?.py.includes(this.searchQuery.productName) ||
        item.desc?.includes(this.searchQuery.productName)
      );
    }
    if (this.searchQuery.product) {
      return item.product?.id === this.searchQuery.product;
    }
    return false;
  }
  matchItems(stockLog: StockLog) {
    return stockLog.items.filter(item => this.searchMatch(item));
  }
  download(aggregateByProduct = false) {
    const params: Record<string, any> = {
      token: window.localStorage.token,
      ...this.query
    };
    if (aggregateByProduct) {
      params.aggregateByProduct = true;
    }
    const queryString = Object.keys(params)
      .filter(key => params[key] !== undefined && params[key] !== null)
      .map(key => `${key}=${params[key]}`)
      .join("&");
    window.location.href =
      process.env.VUE_APP_API_BASE + "/stock-log-sheet?" + queryString;
  }
  downloadProviderGroups() {
    const params: Record<string, any> = {
      token: window.localStorage.token,
      ...this.query
    };
    const queryString = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join("&");
    window.location.href =
      process.env.VUE_APP_API_BASE + "/stock-log-stats?" + queryString;
  }
  async downloadFoodWaive() {
    const params: Record<string, any> = {
      token: window.localStorage.token,
      ...this.query
    };
    this.downloading = true;
    try {
      const { downloadUrl } = (await http.get("/food-waive", { params })).data;
      window.open(downloadUrl);
    } catch (e) {
      //
    }
    this.downloading = false;
  }
  selectFile() {
    (this.$refs["file-upload"] as HTMLInputElement).click();
  }
  onFileChange(e: any) {
    let files = (e.target?.files || e.dataTransfer?.files) as FileList;
    if (!files?.length) return;
    Array.from(files).forEach(file => {
      this.uploadFile(file);
    });
  }
  async uploadFile(file: Blob) {
    const formData = new FormData();
    formData.append("file", file);
    try {
      await http.post("stock-log-import", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        params: { usage: this.searchQuery.usage }
      });
      this.queryData();
    } catch (e) {
      //
    }
    (this.$refs["file-upload"] as HTMLInputElement).value = "";
  }
  showCreate(reason?: string) {
    const query = {
      reason,
      store: this.searchQuery.store,
      dept: this.searchQuery.dept,
      usage: this.searchQuery.usage
    };
    this.$router.push({ path: "/stock-log/add", query });
  }
  async created() {
    if (this.$route.query.product) {
      this.searchQuery.product = this.$route.query.product;
    } else {
      this.searchQuery = {
        ...this.searchQuery,
        date: moment().startOf("month").format("YYYY-MM-DD"),
        dateEnd: moment().endOf("month").format("YYYY-MM-DD")
      };
    }
    if (this.$route.query.store) {
      this.searchQuery.store = this.$route.query.store;
    }
    if (this.$route.query.dept) {
      this.searchQuery.dept = this.$route.query.dept;
    }
    if (this.$route.query.usage) {
      this.searchQuery.usage = this.$route.query.usage;
    }
  }
}
