
























































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Product, StockLogItem, Supply } from "@/resources/interfaces";
import { promptInput, confirm } from "@/helpers/sweetAlert";
import SupplySelect from "./SupplySelect.vue";

@Component({ components: { SupplySelect } })
export default class StockLogItemLine extends Vue {
  @Prop()
  value!: StockLogItem;

  @Prop()
  storeId!: string;

  @Prop({ type: Boolean, default: true })
  editable!: boolean;

  @Prop({ type: Boolean, default: false })
  checkable!: boolean;

  @Prop({ type: Boolean, default: false })
  checked!: boolean;

  @Prop({ default: "" })
  providerSearchTerm!: string;

  @Prop({ type: Boolean, default: false })
  hasPrePostPayProvider!: boolean;

  @Prop()
  reason!: string;

  get lockSupply() {
    return !["采购", "预付/结款"].includes(this.reason);
  }

  get isEditable() {
    if (this.reason === "生产" && this.item.providerName === "库存") {
      return false;
    }
    return this.editable;
  }

  get item() {
    const item = this.value;
    if (["生产", "报废"].includes(this.reason) && item.quantity) {
      item.quantity = Math.abs(item.quantity);
    }
    return item;
  }

  set item(v) {
    this.$emit("input", v);
  }

  getSupplyName(product: Product, supply: Supply) {
    return (
      supply.providerName +
      (supply.productName ? `-${supply.productName}` : "") +
      "-" +
      (supply.unit || product.unit)
    );
  }

  async setLink() {
    const link = await promptInput(
      "输入链接",
      "",
      null,
      "question",
      "text",
      this.item.supplyLink
    );
    if (link === undefined) return;
    this.item.supplyLink = link;
    if (!this.item.providerName) {
      this.checkProviderFromLink();
    }
    this.$emit("input", { ...this.item });
  }

  openProduct(product: Product) {
    window.open("/product/" + product.id);
  }

  checkProviderFromLink() {
    if (this.item.supplyLink?.match(/taobao|tmall/)) {
      this.item.providerName = "淘宝";
    }
    if (this.item.supplyLink?.match(/1688/)) {
      this.item.providerName = "1688";
    }
    if (this.item.supplyLink?.match(/jd.com/)) {
      this.item.providerName = "京东";
    }
    if (this.item.supplyLink?.match(/yangkeduo.com/)) {
      this.item.providerName = "拼多多";
    }
  }

  validSupplies(product?: Product) {
    if (!product?.supplies) return [];
    return product.supplies.filter(
      s =>
        !s.disabled &&
        (((s.store as unknown) as string) === this.storeId || !s.store)
    );
  }

  onSupplyQuantityUpdate(n: number) {
    if (this.item.supplyUnitSpec) {
      this.item.quantity = n * this.item.supplyUnitSpec;
    }
    this.$emit("input", this.item);
  }

  updatePrice(item: StockLogItem, amount: number) {
    if (!item.quantity) return;
    item.price = amount / item.quantity;
  }

  async removeItem() {
    if (
      (this.item.product || this.item.desc) &&
      !(await confirm(
        `确定删除${this.item.product?.name || this.item.desc}`,
        "保存前不会生效，刷新页面可恢复",
        "删除",
        "error"
      ))
    ) {
      return;
    }
    this.$emit("remove");
  }

  checkItem() {
    this.$emit("check");
  }
}
