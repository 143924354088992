















































































































































































import List from "@/components/List";
import { ProductResource, http } from "@/resources";
import { Product } from "@/resources/interfaces";
import Component from "vue-class-component";
import { alert } from "@/helpers/sweetAlert";

@Component
export default class ProductList extends List<Product> {
  name = "product";
  resource = ProductResource;
  saveSearchQueryKeys = [
    "type",
    "category",
    "store",
    "includeDisabled",
    "isSelfMade"
  ];
  categories: string[] = [];
  getProviderNames(product: Product) {
    return Array.from(
      new Set(product.supplies.map(s => s.providerName).filter(n => n))
    );
  }
  selectFile(type = "product") {
    (this.$refs[`file-upload-${type}`] as HTMLInputElement).click();
  }
  onFileChange(type: string, e: any) {
    let files = (e.target?.files || e.dataTransfer?.files) as FileList;
    if (!files?.length) return;
    Array.from(files).forEach(file => {
      this.uploadFile(type, file);
    });
  }
  onSearchTypeChange(type: string) {
    this.loadCategories();
    if (type !== "ingredient") {
      this.searchQuery.isSelfMade = undefined;
    }
  }
  async loadCategories() {
    const type = this.searchQuery.type;
    if (!type) {
      this.categories = [];
    } else {
      this.categories = ((
        await ProductResource.query({
          type: this.searchQuery.type,
          aggregateCategories: true
        })
      ).filter(c => c) as unknown) as string[];
    }
    if (!this.categories.includes(this.searchQuery.category)) {
      this.searchQuery.category = "";
    }
  }
  async uploadFile(type: string, file: Blob) {
    if (type === "product" && !this.searchQuery.type) return;
    const formData = new FormData();
    formData.append("file", file);
    try {
      await http.post(
        `product${type === "product" ? "" : `-${type}`}-import?type=${
          this.searchQuery.type
        }`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      alert(
        `导入${type === "supply" ? "供应" : "商品"}完成`,
        "",
        "关闭",
        "success"
      );
      this.queryData();
    } catch (e) {
      alert(
        `导入${type === "supply" ? "供应" : "商品"}错误`,
        e.message,
        "关闭",
        "error"
      );
    }
    (this.$refs[`file-upload-${type}`] as HTMLInputElement).value = "";
  }
  async updateMenu() {
    await http.post("store-menu", { updateAll: true });
    this.$notify({
      message: "任务已发送",
      icon: "check",
      type: "success"
    });
  }
  showCreate() {
    const query = {
      type: this.searchQuery.type,
      category: this.searchQuery.category
    };
    this.$router.push({ path: "/product/add", query });
  }
  async created() {
    this.searchQuery = {
      type: "",
      category: "",
      store: "",
      includeDisabled: undefined,
      isSelfMade: undefined,
      ...this.searchQuery
    };
    if (this.$route.query.name) {
      this.searchQuery.name = this.$route.query.name;
    }
    if (this.$route.query.formulaProduct) {
      this.searchQuery.formulaProduct = this.$route.query.formulaProduct;
    }
    if (this.searchQuery.type) {
      this.loadCategories();
    }
  }
}
